import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "~/context/AuthContext";

const Login: () => ReactNode = () => {
  const navigate = useNavigate();
  const { signInWithOauth, isLoggedIn } = useAuth();

  async function signInWithGoogle() {
    try {
      const credentials = await signInWithOauth("google");

      if (credentials) {
        navigate("/onboarding");
      }
    } catch (error) {
      console.error("Failed to login with google", error.code, error.message);
    }
  }

  return (
    <main className="min-h-screen w-screen overflow-x-hidden">
      {/* Main content container */}
      <div className="grid md:grid-cols-2 grid-cols-1 min-h-screen relative">
        {/* Left section */}
        <section className="px-6 md:px-12 flex flex-col h-full z-10 pt-6 md:pt-0">
          {/* Header */}
          <div className="flex items-center">
            <span className="bg-white rounded-xl text-[24px] w-[48px] h-[48px] mr-[11px] flex items-center justify-center">
              🐼
            </span>
            <span className="font-[510] text-[24px]">Problem Panda</span>
          </div>

          {/* Main content */}
          <div className="mt-20 md:mt-[200px] relative z-10">
            <div className="text-[32px] md:text-[48px] font-[100]">
              Hi, I'm Paddy the
            </div>

            <div className="text-[48px] md:text-[80px] bg-gradient-to-r from-[#E1AD38] to-[#8EB65A] bg-clip-text text-transparent font-bold leading-tight">
              Problem Panda
            </div>

            <p className="max-w-[530px] mt-6 font-[270] text-[1rem] md:text-[1.1rem]">
              Ready to turn your problems into bamboo-shoots of opportunity?
              <br />
              I'm here to help you think through challenges, set measurable
              goals, and sprout solutions – while keeping things fun!
            </p>

            <button
              onClick={signInWithGoogle}
              className="mt-12 md:mt-24 flex items-center space-x-2 px-6 py-3 bg-white text-gray-700 rounded-lg shadow-md hover:shadow-lg hover:bg-gray-100 transition"
            >
              <img
                src="https://www.svgrepo.com/show/355037/google.svg"
                alt="Google logo"
                className="w-6 h-6"
              />
              <span className="font-medium">Login with Google</span>
            </button>
          </div>
        </section>

        {/* Right section */}
        <section className="bg-bamboo hidden md:block">
          <img
            src="/panda-head.svg"
            className="absolute bottom-[-20px] right-0 z-50 h-[70vh]"
          />
          <img
            src="/panda-hand.svg"
            className="absolute right-[35%] z-0 h-[45vh] bottom-[-20px]"
          />
        </section>

        {/* Mobile panda image */}
        <div className="md:hidden absolute bottom-0 right-0 w-full z-10">
          <img
            src="/panda-head.svg"
            className="relative w-[80%] max-w-[300px] ml-auto z-20"
            alt="Panda mascot"
          />
          <img
            src="/panda-hand.svg"
            className="absolute right-[60%] bottom-0 w-[40%] max-w-[150px] z-10"
            alt="Waving panda hand"
          />
        </div>
      </div>
    </main>
  );
};

export default Login;
