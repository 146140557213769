import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";

import "./styles/variables.css";
import "./styles/utilities.css";
import "./styles/global.css";

import { ApplicationProvider } from "~/context/ApplicationContext";
import { AnalyticsProvider } from "~/context/AnalyticsProvider";
import { LayoutContextProvider } from "~/context/LayoutContext";
import { AvatarAnimationContextProvider } from "~/context/AvatarAnimationContext";
import { HeaderProvider } from "~/context/HeaderContext";

console.log("Running in", import.meta.env.MODE, "mode!");

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ApplicationProvider>
      <AnalyticsProvider>
        <AuthContextProvider>
          <BrowserRouter>
            <LayoutContextProvider>
              <HeaderProvider>
                <App />
              </HeaderProvider>
            </LayoutContextProvider>
          </BrowserRouter>
        </AuthContextProvider>
      </AnalyticsProvider>
    </ApplicationProvider>
  </StrictMode>,
);
