import { useEffect } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import ChatPage from "~/routes/Chat/ChatPage";
import { ChatsProvider } from "~/context/ChatsContext";
import ChatLayout from "~/routes/Chat/ChatLayout";
import ChatIntroPage from "~/routes/Chat/ChatIntroPage";
import { ChatProvider } from "~/context/ChatContext";
import { useHeader } from "~/context/HeaderContext";
import { useUser } from "~/context/UserContext";
import ProblemVoiceButton from "../Voice/ProblemVoiceButton";

export default function ChatRoute() {
  const header = useHeader();

  useEffect(() => {
    header.setVisible(false);
  }, []);

  return (
    <ChatsProvider>
      <Routes>
        <Route
          element={
            <ChatProvider>
              <Outlet />
            </ChatProvider>
          }
        >
          <Route path=":id" element={<ChatPage />} />
        </Route>
      </Routes>
    </ChatsProvider>
  );
}
