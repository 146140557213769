import React, { useEffect, useRef, useState } from "react";
import { useChat } from "~/context/ChatContext";

import ProblemVoiceButton from "../Voice/ProblemVoiceButton";
import { v4 as uuid } from "uuid";
import { getPandaVideo } from "~/routes/ProblemPanda/Onboarding";
import { useUser } from "~/context/UserContext";
import { useChats } from "~/context/ChatsContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAuth } from "~/context/AuthContext";
import c from "classnames";
import { ChatStatus, Role } from "~/types/Chat";
import { serverTimestamp, Timestamp } from "firebase/firestore";

export default function ChatPage() {
  const [searchParams] = useSearchParams();
  const isOnboarding = searchParams.get("onboarding");

  return isOnboarding ? <Onboarding /> : <Conversation />;
}

const Conversation = () => {
  const { chat, addMessage, addVoiceMessage } = useChat();
  const { authUser } = useAuth();
  const [pandaAction, setPandaAction] = useState<string>("LISTENING"); // Default to 'SLEEPING'
  const { user, setOnboardingDone } = useUser();
  const [showTranscript, setShowTranscript] = useState(false);
  const navigate = useNavigate();

  const getIntro = () => {
    if (pandaAction === "SLEEPING") {
      return "Zzzzz...";
    } else {
      return `Welcome ${authUser?.displayName?.split(" ")[0] ?? ""}!`;
    }
  };

  const getBodyText = () => {
    if (pandaAction === "WAKEUP") {
      return `Thanks for the gentle wake-up 🥰`;
    } else {
      return "What's on your mind today?";
    }
  };

  const getSubBodyText = () => {
    switch (pandaAction) {
      case "WAKEUP":
        return `Oh bamboo! I forgot to set my alarm again... Would you mind if we do some quick warm-up exercises while I fully wake up? I promise it'll be fun!`;
      case "SLEEPING":
        return `Give Paddy a gentle tap to wake them and you can start tackling those problems together!`;
      case "LISTENING":
        return "Fun fact: pandas solve problems best after their morning bamboo snack... and I just had mine! 😋";
      case "TALKING":
        return "Fun fact: pandas solve problems best after their morning bamboo snack... and I just had mine! 😋";
      default:
        return "Fun fact: pandas solve problems best after their morning bamboo snack... and I just had mine! 😋";
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="mr-8 ml-8 mt-8 font-medium flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <span className="bg-white rounded-xl text-[24px] w-[48px] h-[48px] mr-[11px] flex items-center justify-center">
              🐼
            </span>
            <span className="font-[510] text-[24px]">Problem Panda</span>
          </div>
          <button
            className="text-[#2E8509] font-medium"
            onClick={() => {
              navigate("/onboarding");
            }}
          >
            <span className="hidden md:inline">New chat</span>
            <span className="md:hidden">New chat</span>
          </button>
        </div>

        <main className="grid w-screen h-screen md:grid-cols-2 grid-cols-1">
          {/* Main chat section */}
          <section className="flex flex-col justify-center align-middle min-h-[100svh]">
            {/* Full-width video container */}
            <div className="w-full flex justify-center">
              {getPandaVideo(pandaAction)}
            </div>

            <section className="flex items-center flex-col w-full px-4 md:px-0">
              <div className="text-2xl md:text-[32px] bg-gradient-to-r from-[#E1AD38] to-[#8EB65A] bg-clip-text text-transparent font-bold text-center">
                {getIntro()}
              </div>
              <p className="mt-4 font-[270] text-xl text-center">
                {getBodyText()}
              </p>
              <p className="mt-4 font-[270] text-sm md:text-base text-center">
                {getSubBodyText()}
              </p>
              <ProblemVoiceButton
                chat={chat}
                isOnboarding={false}
                isOnboardingDone={user.onboardingDone}
                setPandaAction={setPandaAction}
                addVoiceMessage={addVoiceMessage}
                className="custom-gradient px-6 md:px-8 py-3 text-white font-medium rounded-full shadow-md hover:shadow-lg transition duration-300 ease-in-out mt-12 md:mt-16"
              />
            </section>
          </section>

          {/* Transcript section */}
          <section
            className={c("transition-all duration-300 ease-in-out", {
              "fixed inset-0 z-50": showTranscript,
              "hidden md:flex": !showTranscript,
              "md:relative md:flex md:flex-col justify-center": showTranscript,
            })}
          >
            <Transcript
              chat={chat}
              setShowTranscript={setShowTranscript}
              showTranscript={showTranscript}
            />
          </section>
        </main>
      </div>

      {/* Mobile transcript toggle - hidden on desktop */}
      <button
        onClick={() => setShowTranscript(!showTranscript)}
        className="fixed bottom-6 right-6 z-[60] bg-white rounded-full p-3 shadow-lg"
        aria-label="Toggle chat transcript"
      >
        <svg
          className={c("w-6 h-6 transition-transform", {
            "rotate-180": showTranscript,
          })}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.7075 24.7075C24.6146 24.8005 24.5043 24.8742 24.3829 24.9246C24.2615 24.9749 24.1314 25.0008 24 25.0008C23.8686 25.0008 23.7385 24.9749 23.6171 24.9246C23.4957 24.8742 23.3854 24.8005 23.2925 24.7075L9 10.4137V21C9 21.2652 8.89464 21.5196 8.70711 21.7071C8.51957 21.8946 8.26522 22 8 22C7.73478 22 7.48043 21.8946 7.29289 21.7071C7.10536 21.5196 7 21.2652 7 21V8C7 7.73478 7.10536 7.48043 7.29289 7.29289C7.48043 7.10536 7.73478 7 8 7H21C21.2652 7 21.5196 7.10536 21.7071 7.29289C21.8946 7.48043 22 7.73478 22 8C22 8.26522 21.8946 8.51957 21.7071 8.70711C21.5196 8.89464 21.2652 9 21 9H10.4137L24.7075 23.2925C24.8005 23.3854 24.8742 23.4957 24.9246 23.6171C24.9749 23.7385 25.0008 23.8686 25.0008 24C25.0008 24.1314 24.9749 24.2615 24.9246 24.3829C24.8742 24.5043 24.8005 24.6146 24.7075 24.7075Z"
            fill="#171C41"
            opacity="0.9"
          />
        </svg>
      </button>
    </>
  );
};

const Onboarding = () => {
  const [pandaAction, setPandaAction] = useState<string>("SLEEPING");
  const [showPopup, setShowPopup] = useState(false);
  const { addVoiceMessage, chat } = useChat();
  const { createChat } = useChats();
  const { authUser } = useAuth();
  const [showTranscript, setShowTranscript] = useState(false);
  const navigate = useNavigate();

  const { user, setOnboardingDone } = useUser();

  const create = async () => {
    setOnboardingDone();
    const chatId = uuid();
    await createChat({
      id: chatId,
      userId: user!.userId,
      messages: [
        {
          id: uuid(),
          role: Role.USER,
          content: "",
          errorMessage: null,
          model: null,
          platform: null,
          previousMessageId: null,
          updatedAt: new Date() as any,
          idempotencyKey: null,
        },
      ],
      files: {},
      updatedAt: serverTimestamp() as Timestamp,
      status: ChatStatus.REQUESTED,
      departmentId: user.departmentId!,
    });

    navigate(`/chat/${chatId}`);
  };

  useEffect(() => {
    if (user.onboardingDone) {
      setShowPopup(true);
    }
  }, [user]);

  const getIntro = () => {
    if (pandaAction === "SLEEPING") {
      return "Zzzzz...";
    } else {
      return `Welcome, ${authUser?.displayName?.split(" ")[0]}`;
    }
  };

  const getBodyText = () => {
    if (pandaAction === "SLEEPING") {
      return "Paddy's taking a quick snooze";
    } else {
      return `Thanks for the gentle wake-up! 🥰`;
    }
  };

  const getSubBodyText = () => {
    switch (pandaAction) {
      case "WAKEUP":
        return `Oh bamboo! I forgot to set my alarm again... Would you mind if we do some quick warm-up exercises while I fully wake up? I promise it'll be fun!`;
      case "SLEEPING":
        return `Give Paddy a gentle tap to wake them and you can start tackling those problems together!`;
      case "LISTENING":
        return "Oh bamboo! I forgot to set my alarm again... Would you mind if we do some quick warm-up exercises while I fully wake up? I promise it'll be fun!";
      case "TALKING":
        return "Oh bamboo! I forgot to set my alarm again... Would you mind if we do some quick warm-up exercises while I fully wake up? I promise it'll be fun!";
      default:
        return "Oh bamboo! I forgot to set my alarm again... Would you mind if we do some quick warm-up exercises while I fully wake up? I promise it'll be fun!";
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="mr-8 ml-8 mt-8 font-medium flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <span className="bg-white rounded-xl text-[24px] w-[48px] h-[48px] mr-[11px] flex items-center justify-center">
              🐼
            </span>
            <span className="font-[510] text-[24px]">Problem Panda</span>
          </div>
          <button
            className="text-[#2E8509] font-medium"
            onClick={() => setShowPopup(true)}
          >
            <span className="hidden md:inline">Skip the onboarding</span>
            <span className="md:hidden">Skip</span>
          </button>
        </div>

        <main className="grid w-screen h-screen md:grid-cols-2 grid-cols-1">
          {/* Main chat section */}
          <section className="flex flex-col items-center justify-center min-h-[100svh]">
            {/* Full-width video container */}
            <div className="w-full flex justify-center">
              {getPandaVideo(pandaAction)}
            </div>

            <section className="flex items-center flex-col max-w-md w-full px-4 md:px-0">
              <div className="text-2xl md:text-[32px] bg-gradient-to-r from-[#E1AD38] to-[#8EB65A] bg-clip-text text-transparent font-bold text-center">
                {getIntro()}
              </div>
              <p className="mt-4 font-[270] text-2xl md:text-4xl text-center">
                {getBodyText()}
              </p>
              <p className="mt-4 font-[270] text-sm md:text-base text-center">
                {getSubBodyText()}
              </p>
              <ProblemVoiceButton
                chat={chat}
                isOnboarding={true}
                isOnboardingDone={user.onboardingDone}
                setPandaAction={setPandaAction}
                addVoiceMessage={addVoiceMessage}
                className="custom-gradient px-6 md:px-8 py-3 text-white font-medium rounded-full shadow-md hover:shadow-lg transition duration-300 ease-in-out mt-12 md:mt-16"
              />
            </section>
          </section>

          {/* Transcript section */}
          <section
            className={c("transition-all duration-300 ease-in-out", {
              "fixed inset-0 z-50": showTranscript,
              "hidden md:flex": !showTranscript,
              "md:relative md:flex md:flex-col justify-center": showTranscript,
            })}
          >
            <Transcript
              chat={chat}
              setShowTranscript={setShowTranscript}
              showTranscript={showTranscript}
            />
          </section>
        </main>
      </div>

      {/* Mobile transcript toggle - hidden on desktop */}
      <button
        onClick={() => setShowTranscript(!showTranscript)}
        className="fixed bottom-6 right-6 z-[60] bg-white rounded-full p-3 shadow-lg"
        aria-label="Toggle chat transcript"
      >
        <svg
          className={c("w-6 h-6 transition-transform", {
            "rotate-180": showTranscript,
          })}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.7075 24.7075C24.6146 24.8005 24.5043 24.8742 24.3829 24.9246C24.2615 24.9749 24.1314 25.0008 24 25.0008C23.8686 25.0008 23.7385 24.9749 23.6171 24.9246C23.4957 24.8742 23.3854 24.8005 23.2925 24.7075L9 10.4137V21C9 21.2652 8.89464 21.5196 8.70711 21.7071C8.51957 21.8946 8.26522 22 8 22C7.73478 22 7.48043 21.8946 7.29289 21.7071C7.10536 21.5196 7 21.2652 7 21V8C7 7.73478 7.10536 7.48043 7.29289 7.29289C7.48043 7.10536 7.73478 7 8 7H21C21.2652 7 21.5196 7.10536 21.7071 7.29289C21.8946 7.48043 22 7.73478 22 8C22 8.26522 21.8946 8.51957 21.7071 8.70711C21.5196 8.89464 21.2652 9 21 9H10.4137L24.7075 23.2925C24.8005 23.3854 24.8742 23.4957 24.9246 23.6171C24.9749 23.7385 25.0008 23.8686 25.0008 24C25.0008 24.1314 24.9749 24.2615 24.9246 24.3829C24.8742 24.5043 24.8005 24.6146 24.7075 24.7075Z"
            fill="#171C41"
            opacity="0.9"
          />
        </svg>
      </button>

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md text-center">
            <div className="mb-4">
              <div className="w-12 h-12 mx-auto bg-green-100 rounded-full flex items-center justify-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-green-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <h2 className="text-2xl font-bold text-green-600">
                Bamboo-zling!
              </h2>
              <p className="text-lg font-medium text-gray-800 mt-2">
                You're officially a Panda Pro!{" "}
              </p>
            </div>
            <p className="text-gray-600 mb-6">
              You've mastered all the basics of conversing with Paddy. Ready to
              tackle your first problem together?
            </p>
            <button
              onClick={async () => {
                await create();
              }}
              className="px-6 py-2 bg-[#2E8509] text-white font-semibold rounded-md"
            >
              Let's solve some problems! 🐼
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const Transcript = ({ chat, showTranscript, setShowTranscript }) => {
  const { authUser } = useAuth();
  const ref = useRef(null);
  const [newMessageIndex, setNewMessageIndex] = useState(null);
  const prevMessagesLengthRef = useRef(chat.messages.length);

  // Scroll to the bottom when new messages are added
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [chat]);

  // Detect when a new message is added
  useEffect(() => {
    if (chat.messages.length > prevMessagesLengthRef.current) {
      setNewMessageIndex(chat.messages.length - 1);
    }
    prevMessagesLengthRef.current = chat.messages.length;
  }, [chat.messages.length]);

  return (
    <>
      {showTranscript && (
        <div ref={ref} className="transcript-container">
          <h2 className="transcript-header">Chat Transcript</h2>

          {chat.messages.map((it, index) => {
            const role = it.role;
            const name =
              role === "AI" ? "Panda" : authUser?.displayName.split(" ")[0];

            if (index === 0 || index === 1 || it.content.length === 0) {
              return null;
            }

            return (
              <div
                key={index}
                className={`message-row ${
                  index === newMessageIndex ? "new-message" : ""
                }`}
              >
                <span className="message-name">{name}:</span>
                <p className="message-content">{it.content}</p>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
