import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, DocumentReference, setDoc } from "firebase/firestore";

import { db } from "~/integrations/firebase/firestore";
import { User } from "~/types/User";
import { useAnalytics } from "~/context/AnalyticsProvider";

interface UserContext {
  user: User;

  setDepartment(id: string): Promise<void>;

  setOnboardingDone: () => Promise<void>;
}

export const UserContext = createContext<UserContext>(undefined as never);

export const useUser = () => useContext(UserContext);

interface Props {
  userId: string;
  onboardingDone: boolean;
}

export const UserProvider = ({
  children,
  userId,
  onboardingDone,
}: PropsWithChildren<Props>) => {
  const reference = doc(db, "users", userId) as DocumentReference<User>;
  const [user, loading, error] = useDocumentData<User>(reference);
  const { setUserDetails } = useAnalytics();

  useEffect(() => {
    setUserDetails({
      departmentId: user?.departmentId,
    });
  }, [user?.departmentId]);

  if (loading) {
    return null;
  }

  async function setOnboardingDone() {
    return await setDoc(
      reference,
      { userId, departmentId: "problem", onboardingDone: true },
      { merge: true },
    );
  }

  async function setDepartment(departmentId: string) {
    departmentId = "problem";
    return await setDoc(
      reference,
      { userId, departmentId, onboardingDone },
      { merge: true },
    );
  }

  return (
    <UserContext.Provider
      value={{
        user: user ?? { userId, onboardingDone },
        setDepartment,
        setOnboardingDone,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
