import { auth } from "../../integrations/firebase/auth";
import { useEffect, useRef, useState } from "react";
import Button from "../../components/Button"; // Import the Button component
import { RealtimeClient } from "@openai/realtime-api-beta";
import { WavStreamPlayer, WavRecorder } from "../../wavtools";
import { Chat, Role } from "~/types/Chat";

interface Props {
  startChat?: (() => Promise<void>) | undefined;
  addVoiceMessage?:
    | ((
        message: string,
        role: Role,
        previousMessageId?: string,
      ) => Promise<void>)
    | undefined;
  chat?: Chat;
  className?: string;
  isOnboarding: boolean;
  isOnboardingDone?: boolean;
  setPandaAction: (arg: string) => void;
}

export default function ProblemVoiceButton({
  startChat,
  addVoiceMessage,
  chat,
  className,
  isOnboarding = false,
  isOnboardingDone,
  setPandaAction,
}: Props) {
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [audioPlayer, setAudioPlayer] = useState<WavStreamPlayer | undefined>();
  const [client, setClient] = useState<RealtimeClient | undefined>();
  const [audioRecorder, setAudioRecorder] = useState<WavRecorder | undefined>();
  const hasStartedVoice = useRef(false);
  const pandaActionChangedTimestamp = useRef(0);

  useEffect(() => {
    return () => {
      if (isSessionActive) {
        stopSession();
      }
    };
  }, [isSessionActive]);

  useEffect(() => {
    const isVoice =
      new URLSearchParams(window.location.search).get("voice") === "true";

    if (
      isVoice &&
      !hasStartedVoice.current &&
      (!chat || chat?.messages.length === 0)
    ) {
      hasStartedVoice.current = true;
      startSession();
    }
  }, []);

  useEffect(() => {
    if (isSessionActive) {
      stopSession();
    }
  }, []);

  let pandaValue = null;

  useEffect(() => {
    if (client && audioPlayer) {
      client.on("conversation.updated", (event: any) => {
        const { item, delta } = event;

        if (
          item.role === "assistant" &&
          delta &&
          Date.now() - pandaActionChangedTimestamp.current > 300
        ) {
          setPandaAction((prev) => {
            if (prev === "TALKING") {
              return prev; // Return the current state if no update is needed
            }
            return "TALKING";
          });
        }
        queueAudio(item, delta);
      });

      client.on("conversation.interrupted", () => {
        if (Date.now() - pandaActionChangedTimestamp.current > 300) {
          setPandaAction("LISTENING");
        }
        audioPlayer.interrupt();
      });

      // Setup for application control flow
      const handler = (event: any, ...args: any[]) => {
        const { item, delta } = client.conversation.processEvent(
          event,
          ...args,
        );
        return { item, delta };
      };
      const handlerWithDispatch = (event: any, ...args: any[]) => {
        const { item, delta } = handler(event, ...args);
        if (item) {
          // FIXME: If statement is only here because item.input_audio_transcription.completed
          //        can fire before `item.created`, resulting in empty item.
          //        This happens in VAD mode with empty audio
          client.dispatch("conversation.updated", { item, delta });
        }
        return { item, delta };
      };

      client.realtime.on("server.session.updated", async (event: any) => {
        let buffer = "Transcript of the conversation so far:\n";
        if (
          chat?.messages.length &&
          chat.messages.length > 0 &&
          client.conversation.items.length === 0
        ) {
          for (const message of chat.messages) {
            if (message.role === Role.USER) {
              buffer += `User: ${message.content}\n`;
            } else if (message.role === Role.AI) {
              buffer += `Assistant: ${message.content}\n`;
            }
          }
          if (buffer.length > 0) {
            await client.realtime.send("conversation.item.create", {
              type: "conversation.item.create",
              item: {
                type: "message",
                role: "user",
                content: [{ type: "input_text", text: buffer }],
              },
            });
          }
        }
      });

      client.realtime.off("server.response.output_item.done");

      client.realtime.on(
        "server.response.output_item.done",
        async (event: any) => {
          const { item } = handlerWithDispatch(event);
          if (item.status === "completed") {
            client.dispatch("conversation.item.completed", { item });
            if (addVoiceMessage) {
              addVoiceMessage(
                item.formatted.transcript.length > 0
                  ? item.formatted.transcript
                  : item.formatted.text,
                Role.AI,
              );
            }
          }
        },
      );

      client.realtime.on(
        "server.conversation.item.input_audio_transcription.completed",
        async (event: any) => {
          if (addVoiceMessage && event.transcript) {
            addVoiceMessage(event.transcript, Role.USER);
          }
        },
      );

      client.connect();
    }
  }, [client, audioPlayer]);

  useEffect(() => {
    if (audioRecorder) {
      audioRecorder.begin().then(() => {
        audioRecorder.record((data) => {
          try {
            const { mono, raw } = data;
            if (client) {
              client.appendInputAudio(mono);
            }
          } catch (e) {
            if (isSessionActive) {
              stopSession();
            }
            // TODO: add toast, error & please restart session
            console.error(
              "An error occurred, while trying to send audio to Realtime API.",
              e,
            );
          }
        });
      });
    }
  }, [audioRecorder]);

  const initAudioPlayer = async () => {
    const player = new WavStreamPlayer({ sampleRate: 24000 });
    setAudioPlayer(player);
    await player.connect();
  };

  const initClient = async () => {
    const idToken = await auth.currentUser?.getIdToken(true);
    const client = new RealtimeClient({
      url: `${import.meta.env.VITE_RELAY_URL}${
        isOnboarding ? "/onboarding" : ""
      }`,
      apiKey: idToken,
      dangerouslyAllowAPIKeyInBrowser: true,
    });
    setClient(client);
  };

  const initAudioRecorder = async () => {
    const recorder = new WavRecorder({ sampleRate: 24000 });
    setAudioRecorder(recorder);
  };

  const queueAudio = (event: any, delta: any) => {
    if (audioPlayer && delta?.audio) {
      audioPlayer.add16BitPCM(delta.audio, event.id);
    }
  };

  const startSession = async () => {
    if (isOnboarding) {
      setPandaAction("LISTENING");
    }

    if (startChat) {
      await startChat();
    } else {
      if (!isSessionActive) {
        await initClient();
        await initAudioPlayer();
        await initAudioRecorder();
        setIsSessionActive(true);
      }
    }
  };

  const stopSession = async () => {
    setIsSessionActive(false);
    if (audioPlayer) {
      await audioPlayer.interrupt();
      setAudioPlayer(undefined);
    }
    if (audioRecorder) {
      await audioRecorder.pause();
      await audioRecorder.quit();
      audioRecorder.listenForDeviceChange(null);
      setAudioRecorder(undefined);
    }
    if (client) {
      client.disconnect();
      setClient(undefined);
    }
  };

  const ripples = [
    <div key={"1"} className="ripple"></div>,
    <div key={"2"} className="ripple"></div>,
    <div key={"23"} className="ripple"></div>,
    <div key={"42"} className="ripple"></div>,
  ];

  return (
    <button
      className={`mic-button ${className}`}
      onClick={isSessionActive ? stopSession : startSession}
    >
      <i className="mic-icon flex items-center">
        ️
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 16.5C13.1931 16.4988 14.337 16.0243 15.1806 15.1806C16.0243 14.337 16.4988 13.1931 16.5 12V6C16.5 4.80653 16.0259 3.66193 15.182 2.81802C14.3381 1.97411 13.1935 1.5 12 1.5C10.8065 1.5 9.66193 1.97411 8.81802 2.81802C7.97411 3.66193 7.5 4.80653 7.5 6V12C7.50124 13.1931 7.97575 14.337 8.81939 15.1806C9.66303 16.0243 10.8069 16.4988 12 16.5ZM9 6C9 5.20435 9.31607 4.44129 9.87868 3.87868C10.4413 3.31607 11.2044 3 12 3C12.7956 3 13.5587 3.31607 14.1213 3.87868C14.6839 4.44129 15 5.20435 15 6V12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12V6ZM12.75 19.4625V22.5C12.75 22.6989 12.671 22.8897 12.5303 23.0303C12.3897 23.171 12.1989 23.25 12 23.25C11.8011 23.25 11.6103 23.171 11.4697 23.0303C11.329 22.8897 11.25 22.6989 11.25 22.5V19.4625C9.40091 19.2743 7.68728 18.4072 6.44048 17.0288C5.19368 15.6504 4.50228 13.8586 4.5 12C4.5 11.8011 4.57902 11.6103 4.71967 11.4697C4.86032 11.329 5.05109 11.25 5.25 11.25C5.44891 11.25 5.63968 11.329 5.78033 11.4697C5.92098 11.6103 6 11.8011 6 12C6 13.5913 6.63214 15.1174 7.75736 16.2426C8.88258 17.3679 10.4087 18 12 18C13.5913 18 15.1174 17.3679 16.2426 16.2426C17.3679 15.1174 18 13.5913 18 12C18 11.8011 18.079 11.6103 18.2197 11.4697C18.3603 11.329 18.5511 11.25 18.75 11.25C18.9489 11.25 19.1397 11.329 19.2803 11.4697C19.421 11.6103 19.5 11.8011 19.5 12C19.4977 13.8586 18.8063 15.6504 17.5595 17.0288C16.3127 18.4072 14.5991 19.2743 12.75 19.4625Z"
            fill="white"
          />
        </svg>
      </i>
      {isSessionActive && ripples}
    </button>
  );
}
