import React, { useEffect } from "react";
import { useUser } from "~/context/UserContext";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import { useChats } from "~/context/ChatsContext";
import { ChatStatus, Role } from "~/types/Chat";

export const getPandaVideo = (pandaMood: string) => {
  const videoSrc = {
    TALKING: [
      "/panda-talking-1.webm",
      "/panda-talking-2.webm",
      "/panda-talking-3.webm",
    ],
    LISTENING: [
      "/panda-listening-1.webm",
      "/panda-listening-2.webm",
      "/panda-listening-3.webm",
    ],
    WAKEUP: ["/panda-idle-1.webm", "/panda-idle-2.webm"],
    SAVING: ["/tool-use-1.webm", "/tool-use-2.webm"],
    SLEEPING: ["/panda-sleeping.webm"],
    DEFAULT: ["/panda-idle-1.webm", "/panda-idle-2.webm"],
  };

  if (!videoSrc[pandaMood]) {
    pandaMood = "DEFAULT";
  }

  const randomIndex = Math.floor(Math.random() * videoSrc[pandaMood].length);
  return (
    <video
      key={pandaMood}
      height="350"
      className="h-[350px] object-cover"
      loop
      autoPlay
      muted
    >
      <source
        src={videoSrc[pandaMood][randomIndex] || videoSrc.DEFAULT}
        type="video/webm"
      />
    </video>
  );
};

const Onboarding = () => {
  const { user } = useUser();
  const { createChat } = useChats();
  const navigate = useNavigate();

  const chatId = uuid();

  useEffect(() => {
    const create = async () => {
      await createChat({
        id: chatId,
        userId: user!.userId,
        messages: [
          {
            id: uuid(),
            role: Role.USER,
            content: "",
            errorMessage: null,
            model: null,
            platform: null,
            previousMessageId: null,
            updatedAt: new Date() as any,
            idempotencyKey: null,
          },
        ],
        files: {},
        updatedAt: serverTimestamp() as Timestamp,
        status: ChatStatus.REQUESTED,
        departmentId: user.departmentId!,
      });
    };

    create().then(() => {
      // TODO: add of
      if (user.onboardingDone) {
        navigate(`/chat/${chatId}`);
      } else {
        navigate(`/chat/${chatId}?onboarding=true`);
      }
    });
  }, []);
};

export default Onboarding;
