import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import React from "react";

import { useAuth } from "~/context/AuthContext";
import Login from "~/routes/Login";
import { UserProvider } from "~/context/UserContext";
import { DepartmentsProvider } from "~/context/DepartmentsContext";
import { FilesProvider } from "~/context/FilesContext";
import Onboarding from "~/routes/ProblemPanda/Onboarding";
import "./styles/App.css";
import "./styles/style.css";
import ChatRoute from "~/routes/Chat/ChatRoute";
import { ChatsProvider } from "~/context/ChatsContext";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      {/* Protected Routes */}
      <Route element={<AuthLayout />}>
        <Route
          path="onboarding"
          element={
            <ChatsProvider>
              <Onboarding />
            </ChatsProvider>
          }
        />
        <Route path="/chat/*" element={<ChatRoute />} />
        <Route path="*" element={<Navigate to="/onboarding" replace />} />
      </Route>
    </Routes>
  );
}

const AuthLayout = () => {
  const { authUser, loading } = useAuth();

  if (loading) {
    return null; // TODO: Add a loading indicator
  }

  if (!authUser) {
    console.log("Redirecting to login");
    return <Navigate to="/login" replace />;
  }

  return (
    <DepartmentsProvider>
      <UserProvider userId={authUser.uid}>
        <FilesProvider>
          <Outlet />
        </FilesProvider>
      </UserProvider>
    </DepartmentsProvider>
  );
};

export default AppRoutes;
